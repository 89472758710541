var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{class:["columns"]},[_c('div',{class:["column"]},[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Name:")]),_c('b-input',{attrs:{"placeholder":"Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Description:")]),_c('b-input',{attrs:{"placeholder":"Description","maxlength":"200","type":"textarea"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1),_c('div',{class:[_vm.$style.inputContainer]},[_c('b-field',{attrs:{"label":"Badge Type"}},[_c('ValidationProvider',{attrs:{"name":"Badge Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"placeholder":"Select a type"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},_vm._l((_vm.types),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.formData.type === 'WEEKLY_ATTENDANCE')?[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Min. Attendance Count in a week","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Min. Attendance Count in a week:")]),_c('b-input',{attrs:{"placeholder":"Min. Attendance Count"},model:{value:(_vm.formData.attendanceCount),callback:function ($$v) {_vm.$set(_vm.formData, "attendanceCount", $$v)},expression:"formData.attendanceCount"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),(_vm.formData.type === 'QUESTION_BANK')?[_c('div',{class:[_vm.$style.inputContainer]},[_c('ValidationProvider',{attrs:{"name":"Min. Question Bank Answered","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',{staticClass:"label"},[_vm._v("Min. Question Bank Answered:")]),_c('b-input',{attrs:{"placeholder":"Min. Question Bank Answered"},model:{value:(_vm.formData.questionBankAnswered),callback:function ($$v) {_vm.$set(_vm.formData, "questionBankAnswered", $$v)},expression:"formData.questionBankAnswered"}}),_c('span',{class:_vm.$style.errorMessage},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('div',{class:[_vm.$style.inputContainer]},[_c('h5',{staticClass:"label"},[_vm._v("Status:")]),_c('b-switch',{model:{value:(_vm.formData.isactive),callback:function ($$v) {_vm.$set(_vm.formData, "isactive", $$v)},expression:"formData.isactive"}},[_vm._v(" "+_vm._s(_vm.formData.isactive ? "Enabled" : "Disabled")+" ")])],1)],2),_c('div',{class:["column"]},[_c('div',{class:_vm.$style.inputContainer},[_c('h5',{staticClass:"label"},[_vm._v("Badge Icon (5MB max)")]),_c('Upload',{class:_vm.$style.uploadContainer,attrs:{"fileTypes":_vm.imageFileTypes,"previousSelectedFile":_vm.formData.imageurl,"showDeleteButton":true},on:{"input":_vm.onBadgeIconUpload}})],1)])]),_c('div',{class:_vm.$style.buttonContainer},[_c('input',{attrs:{"type":"submit","disabled":invalid},domProps:{"value":_vm.isEdit ? 'Update' : 'Add'}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }