import { axios } from '@/configs/axios';

export async function uploadAttachment(
  data: File,
  courseName: string,
  type: string,
  fileSizeLimit?: number
): Promise<string> {
  const payload: FormData = new FormData();
  payload.append(`file`, data);
  payload.append(`course_name`, courseName);
  payload.append(`type`, type);

  // If value is more than 10mb, increase timeout duration to 1 minute
  if (fileSizeLimit && fileSizeLimit > 10) {
    const response = await axios.post(`/media`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 60000,
    });

    return response.data.data;
  }

  const response = await axios.post(`/media`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data;
}

export async function uploadMediaFile(data: File): Promise<string> {
  const payload: FormData = new FormData();
  payload.append(`file`, data);

  const response = await axios.post(`/media/upload`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data;
}

export async function uploadQuestionBankAttachment(file: File): Promise<string> {
  const payload: FormData = new FormData();
  payload.append(`file`, file);
  const response = await axios.post(`/media/qb`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data;
}

export async function uploadUserProfilePicture(file: File, email: string | null): Promise<string> {
  const payload: FormData = new FormData();
  if (email) {
    payload.append(`email`, email);
  }
  payload.append(`file`, file);
  const response = await axios.post(`/media/user`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data.data;
}
