import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateBadgeFormPayload,
  UpdateBadgeFormPayload,
  BadgeResponse,
  BadgesResponse,
  PaginatedBadges,
  AwardUserBadgeFormPayload,
} from '@/models/badges';

const defaultPaginatedBadges = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

@Module({
  namespaced: true,
  dynamic: true,
  name: `badge`,
  store,
})
class BadgeStore extends VuexModule {
  isPendingBadges = false;
  isPendingBadge = false;
  isCreatingBadge = false;
  isUpdatingBadge = false;
  isDeletingBadge = false;
  isAwardingUserBadge = false;

  badgesError: any = null;
  badgeError: any = null;
  createBadgeError: any = null;
  updateBadgeError: any = null;
  deleteBadgeError: any = null;
  awardUserBadgeError: any = null;
  badges: PaginatedBadges = {
    ...defaultPaginatedBadges,
  };
  badge: BadgeResponse['data'] | null = null;

  @Mutation
  GET_BADGES_LOADING() {
    this.isPendingBadges = true;
    this.badgesError = null;
    this.badges = defaultPaginatedBadges;
  }
  @Mutation
  GET_BADGES_SUCCESS(payload: PaginatedBadges) {
    this.isPendingBadges = false;
    this.badges = {
      ...this.badges,
      ...payload,
    };
  }
  @Mutation
  GET_BADGES_ERROR(payload: any) {
    this.isPendingBadges = false;
    this.badgesError = payload;
  }

  @Mutation
  GET_BADGE_LOADING() {
    this.isPendingBadges = true;
    this.badgeError = null;
    this.badge = null;
  }
  @Mutation
  GET_BADGE_SUCCESS(payload: BadgeResponse['data']) {
    this.isPendingBadge = false;
    this.badge = payload;
  }
  @Mutation
  GET_BADGE_ERROR(payload: any) {
    this.isPendingBadge = false;
    this.badgeError = payload;
  }

  @Mutation
  CREATE_BADGE_LOADING() {
    this.isCreatingBadge = true;
    this.createBadgeError = null;
  }
  @Mutation
  CREATE_BADGE_SUCCESS(payload: any) {
    this.isCreatingBadge = false;
  }
  @Mutation
  CREATE_BADGES_ERROR(payload: any) {
    this.isCreatingBadge = false;
    this.createBadgeError = payload;
  }

  @Mutation
  UPDATE_BADGE_LOADING() {
    this.isUpdatingBadge = true;
    this.updateBadgeError = null;
  }
  @Mutation
  UPDATE_BADGE_SUCCESS() {
    this.isUpdatingBadge = false;
  }
  @Mutation
  UPDATE_BADGES_ERROR(payload: any) {
    this.isUpdatingBadge = false;
    this.updateBadgeError = payload;
  }

  @Mutation
  AWARD_USER_BADGE_LOADING() {
    this.isAwardingUserBadge = true;
    this.awardUserBadgeError = null;
  }

  @Mutation
  AWARD_USER_BADGE_SUCCESS() {
    this.isAwardingUserBadge = false;
  }
  @Mutation
  AWARD_USER_BADGE_ERROR(payload: any) {
    this.isAwardingUserBadge = false;
    this.awardUserBadgeError = payload;
  }

  @Mutation
  DELETE_BADGE_LOADING() {
    this.isDeletingBadge = true;
    this.deleteBadgeError = null;
  }
  @Mutation
  DELETE_BADGE_SUCCESS() {
    this.isDeletingBadge = false;
  }
  @Mutation
  DELETE_BADGES_ERROR(payload: any) {
    this.isDeletingBadge = false;
    this.deleteBadgeError = payload;
  }

  @Action
  getBadges({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_BADGES_LOADING`);

    axios
      .get<BadgesResponse>(`/achievement/badge/list`, {
        params: {
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_BADGES_SUCCESS`, {
            data: response.data.data.objects,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching badges data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_BADGES_ERROR`, error);
      });
  }

  @Action
  getBadge(id: number) {
    this.context.commit(`GET_BADGE_LOADING`);

    axios
      .get<BadgeResponse>(`/achievement/badge/list/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_BADGE_SUCCESS`, response.data.data);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching badges data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_BADGE_ERROR`, error);
      });
  }

  @Action
  createBadge(params: CreateBadgeFormPayload) {
    this.context.commit(`CREATE_BADGE_LOADING`);

    axios
      .post<BadgeResponse>(`/achievement/badge`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_BADGE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created badge.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating badges data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_BADGE_ERROR`, error);
      });
  }

  @Action
  updateBadge(payload: UpdateBadgeFormPayload) {
    this.context.commit(`UPDATE_BADGE_LOADING`);

    axios
      .put<BadgeResponse>(`/achievement/badge/${payload.id}`, { ...payload }, { timeout: 60000 })
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_BADGE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Badge successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with badge update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_BADGE_ERROR`, error);
      });
  }

  @Action
  awardUserBadge(payload: AwardUserBadgeFormPayload) {
    this.context.commit(`AWARD_USER_BADGE_LOADING`);
    const { id, ...rest } = payload;

    axios
      .post<BadgeResponse>(`/achievement/badge/${id}/award-user`, { ...rest }, { timeout: 60000 })
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`AWARD_USER_BADGE_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Badge successfully assigned!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with badge award process. Please try again. Error: ${response.data.error}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`AWARD_USER_BADGE_ERROR`, error);
      });
  }
}

export const BadgeStores = getModule(BadgeStore);
