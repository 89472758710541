








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { BadgeStores } from '@/store/badges';
import BadgeForm from '@/components/badges/BadgeForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    BadgeForm,
  },
})
export default class AddBadges extends Vue {
  badgeStore = BadgeStores;

  unsubscribe: () => void = () => {};

  handleSubmit(payload: any) {
    this.badgeStore.createBadge(payload);
  }

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'badge/CREATE_BADGE_SUCCESS') {
        this.$router.replace('/badges');
      }
    });
  }

  beforeDestroy() {
    this.unsubscribe();
  }
}
