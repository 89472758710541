

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import Upload from '@/components/common/Upload.vue';
import { BadgeResponse, CreateBadgeFormPayload } from '@/models/badges';
import { AcceptedImageFileType } from '@/utils/constants';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
import { ValidationObserver } from 'vee-validate';
import { uploadMediaFile } from '@/utils/attachments';
import startCase from 'lodash/startCase';

const badgeTypes = ['WEEKLY_ATTENDANCE','QUESTION_BANK','SPECIAL','MARKETING','PREMIUM_USER'];

@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
  },
})
export default class BadgeForm extends Vue {
  @Prop({ default: false })
  isEdit!: boolean;

  @Prop({ default: '' })
  defaultValues!: BadgeResponse['data'];

  formData: CreateBadgeFormPayload = {
    name: '',
    description: '',
    imageurl: '',
    type: '',
    isactive: true,
    attendanceCount: undefined,
    questionBankAnswered: undefined,
  };

  reupload = false;

  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  get types() {
    return [...badgeTypes].map((item) => ({
      label: startCase(item),
      value: item,
    }));
  }


  mounted() {
    if (this.isEdit) {
      this.formData = {
        ...this.defaultValues,
        ...(this.defaultValues.type === 'WEEKLY_ATTENDANCE' && {
          attendanceCount: this.defaultValues.criteria.find((item) => item.fact === 'attendanceCount')?.value,
        }),
        ...(this.defaultValues.type === 'QUESTION_BANK' && {
          questionBankAnswered: this.defaultValues.criteria.find((item) => item.fact === 'questionBankAnswered')?.value,
        }),
      };
    }
  }

  async onBadgeIconUpload(payload: UploadAttachmentWithThumbnail) {
    if (!payload.originalFile) {
      return;
    }

    const fileUrl = await uploadMediaFile(payload.originalFile as File);
    this.formData.imageurl = fileUrl;
  }

  onSubmit() {
    const { ...formData } = this.formData;

    this.$emit('submit', {
      ...formData,
    });
  }
}
